import { Button, Col, Divider, Row } from 'antd'
import React, { useState, useEffect } from 'react'
import { RealtimeData_Request } from '../../api'
import { CModel, CModelMap, GModel } from '../../DataRequest/DataModel'
import { DataSortRT } from '../../DataRequest/RealTimeRequestWrap'
import HMIWrapper from '../Realtime/HMIWrapper'
import RealTimeGraphWrapper from '../Realtime/RealTimeGraphWrapper'
import RealTimeTableWrapper from '../Realtime/RealTimeTableWrapper'
import PolymerC from './HMI/PolymerC'
import PolymerG from './HMI/PolymerG'

let CDataModel = CModel
let GDataModel = GModel
let BlankPage = 1

let RealTimeValue = {
    "CHMI": CDataModel,
    "GHMI": GDataModel,
    "CTable": [],
    "GTable": [],
    "CGraph": [],
    "GGraph": [],
}




const Line1RT = (props) => {

    console.log(props)
    let lineNo = props.match.params.id

    const [state, setState] = useState({ count: 0, count2: 100 });

    const DataRequest = async (label, site, page, line, HMIName, TableName) => {

        const response = await RealtimeData_Request(label, site, page, line)
        RealTimeValue[TableName] = DataSortRT(response)
        RealTimeValue[HMIName] = RealTimeValue[TableName]
        console.log(RealTimeValue[HMIName]);
    }


    const RenderNewData = () => {

        DataRequest([], 'Polymerlink', BlankPage, 'C'.concat(lineNo), 'CHMI', "CTable")
        DataRequest([], 'Polymerlink', BlankPage, 'G'.concat(lineNo), 'GHMI', "GTable")

        setState(({ count }) => ({ count: count + 1 }));
    }


    useEffect(() => {
        RealTimeValue = {
            "CTable": [],
            "GTable": [],
            "CGraph": [],
            "GGraph": [],
        }
        console.log(RealTimeValue);
        
        RenderNewData()

        const interval = setInterval(() => {
            RenderNewData()
        }, 3000)

        return () => {
            clearInterval(interval)
            console.log('Unmount Effect Line1RT');
        }

    }, [props])


    return (
        <div>
            <HMIWrapper Tab={['C'.concat(lineNo), 'G'.concat(lineNo)]}>
                <PolymerC key={'C'.concat(lineNo)} Parameter={RealTimeValue['CHMI']} />
                <PolymerG key={'G'.concat(lineNo)} Parameter={RealTimeValue['GHMI']} />
            </HMIWrapper>

            <Divider orientation="left">Parameter List</Divider>

            <div>
                <Row gutter={[16, 16]}>
                    <Col span={12} xs={24} xl={12}>
                        <RealTimeTableWrapper TableTitle={'C'.concat(lineNo)} TableData={RealTimeValue['CTable']} />
                    </Col>
                    <Col span={12} xs={24} xl={12}>
                        <RealTimeTableWrapper TableTitle={'G'.concat(lineNo)} TableData={RealTimeValue['GTable']} />
                    </Col>

                </Row>
            </div>

            <div>
                <Row gutter={[16, 16]}>

                    <Col span={12} xs={24} xl={12}>
                        <RealTimeGraphWrapper GarphTitle={'C'.concat(lineNo)}
                            DataModel={CModelMap}
                            RawDataReceive={RealTimeValue['CTable']}
                            Divname={'CGraph'}
                        />
                    </Col>

                    <Col span={12} xs={24} xl={12}>
                        <RealTimeGraphWrapper GarphTitle={'G'.concat(lineNo)}
                            DataModel={GDataModel}
                            RawDataReceive={RealTimeValue['GTable']}
                            Divname={'GGraph'}
                        />
                    </Col>

                </Row>
            </div>


        </div>
    )
}

export default Line1RT
