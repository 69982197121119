import React from 'react'
import NotificationC from './NotificationC3'
import NotificationG from './NotificationG2'


const NotificationLine1 = () => {
    return (
        <div>
            <NotificationC lineNo={1}/>
            <NotificationC lineNo={2}/>
            <NotificationC lineNo={3}/>
            <NotificationG lineNo={1}/>
            <NotificationG lineNo={2}/>
            <NotificationG lineNo={3}/>
        </div>
    )
}

export default NotificationLine1