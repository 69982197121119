import React, { useEffect, useState } from 'react'
import { ReadJob, RealtimeData_Request } from '../../api'
import { FilterOutEndedJob, GetDateTime, ReportSort, TableColumbJob, TableColumbJobHome } from '../../DataRequest/LogDataRequestWrap';
import moment from 'moment';
import { CModel, GModel, JobModel } from '../../DataRequest/DataModel';
import { Button, Col, Row, Table, Typography } from 'antd';
import RealTimeTableWrapper from '../Realtime/RealTimeTableWrapper';
import { DataSortRT } from '../../DataRequest/RealTimeRequestWrap';


let LogValue = {
    "LogC3": [],
    "LogG2": [],
}

let ReportList = {
    "C1ReportList": [],
    "C2ReportList": [],
    "C3ReportList": [],
    "G1ReportList": [],
    "G2ReportList": [],
    "G3ReportList": [],
    
    'All': []
}

let JobDetailRecord = {
    datetimeStart: '',
    datetimeEnd: '',
    site: '',
    line: '',
    BufferName: ''
}

let ReportInfoG2 = {}

let BlankPage = 1, ClickTime

let RealTimeValue = {
    "C1Table": [],
    "C2Table": [],
    "C3Table": [],
    "G1Table": [],
    "G2Table": [],
    "G3Table": [],

    "C1Graph": [],
    "C2Graph": [],
    "C3Graph": [],
    "G1Graph": [],
    "G2Graph": [],
    "G3Graph": [],
}

const Home = () => {

    const { Title } = Typography

    const [state, setState] = useState({ count: 0 });

    const DataRequest = async (label, site, page, line, HMIName, TableName) => {
        const response = await RealtimeData_Request(label, site, page, line)
        RealTimeValue[TableName] = DataSortRT(response)
        RealTimeValue[HMIName] = RealTimeValue[TableName]
    }

    const RenderNewData = () => {

        DataRequest([], 'Polymerlink', BlankPage, 'C1', 'C1HMI', "C1Table")
        DataRequest([], 'Polymerlink', BlankPage, 'C2', 'C2HMI', "C2Table")
        DataRequest([], 'Polymerlink', BlankPage, 'C3', 'C3HMI', "C3Table")
        DataRequest([], 'Polymerlink', BlankPage, 'G1', 'G1HMI', "G1Table")
        DataRequest([], 'Polymerlink', BlankPage, 'G2', 'G2HMI', "G2Table")
        DataRequest([], 'Polymerlink', BlankPage, 'G3', 'G3HMI', "G3Table")

        setState(({ count }) => ({ count: count + 1 }));
    }


    useEffect(() => {
        RenderNewData()
        time()

        const interval = setInterval(() => {
            RenderNewData()
        }, 3000)

        return () => {
            clearInterval(interval)
            console.log('Unmount Effect Line1RT');
        }

    }, [])


    const RequestJob = async (info, datetimeStart, datetimeEnd, site, line, BufferName) => {

        for (let i = 0; i < 6; i++) {
            const response = await ReadJob(info, datetimeStart, datetimeEnd, site, line[i])
            filter(response, BufferName[i])
            console.log();

            ReportList['All'] = []
            ReportList[BufferName[i]].forEach(element => {
                ReportList['All'].push(element)
            });
        }

        console.log(ReportList['All']);


        setState(({ count }) => ({ count: count + 1 }));
    }

    const filter = (response, BufferName) => {
        console.log(response)
        let data = ReportSort(response)
        console.log(data)
        let OngoingJob = FilterOutEndedJob(data)
        console.log(OngoingJob)
        ReportList[BufferName] = OngoingJob
    }



    const time = (line, BufferName) => {
        const timetange = GetDateTime(2, 20)
        RequestJob([], timetange[0], timetange[1], 'Polymerlink', ['G1','G2','G3','C1','C2','C3'], ["G1ReportList", "G2ReportList", "G3ReportList", "C1ReportList", "C2ReportList", "C3ReportList"])
    }


    const ColumbJob = TableColumbJobHome(JobModel, 'G2', BlankPage, 'Log')

    const comparedate = () => {
        const ii = new Date()
        const NowTime = moment(ii).format('DD-MM-yyyy HH:mm:ss');

        let dat1 = '11-12-2021 01:00:00'
        let dat2 = '01-12-2021 23:00:00'

        var date1 = new Date(dat1);
        var date2 = new Date(dat2);

        console.log(NowTime);

        date2 > date1 ? console.log('yes') : console.log('no');

    }



    return (
        <div>
            {/* <Button onClick={time}> test </Button>
            <Button onClick={comparedate}> compare </Button>
             */}
            <Title level={4}>Current Job:</Title>
            <Table columns={ColumbJob} dataSource={ReportList['All']} bordered scroll={{ x: 1000, y: 500 }} pagination={false} size="small" />

            <div>
                <Row gutter={[16, 16]}>
                    <Col span={12} xs={24} xl={12}>
                        <RealTimeTableWrapper TableTitle={'C1'} TableData={RealTimeValue['C1Table']} />
                    </Col>
                    <Col span={12} xs={24} xl={12}>
                        <RealTimeTableWrapper TableTitle={'G1'} TableData={RealTimeValue['G1Table']} />
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    <Col span={12} xs={24} xl={12}>
                        <RealTimeTableWrapper TableTitle={'C2'} TableData={RealTimeValue['C2Table']} />
                    </Col>
                    <Col span={12} xs={24} xl={12}>
                        <RealTimeTableWrapper TableTitle={'G2'} TableData={RealTimeValue['G2Table']} />
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    <Col span={12} xs={24} xl={12}>
                        <RealTimeTableWrapper TableTitle={'C3'} TableData={RealTimeValue['C3Table']} />
                    </Col>
                    <Col span={12} xs={24} xl={12}>
                        <RealTimeTableWrapper TableTitle={'G3'} TableData={RealTimeValue['G3Table']} />
                    </Col>
                </Row>
            </div>

        </div>
    )
}

export default Home
