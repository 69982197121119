let success_state = false
let userinfo = {
    "username": "",
    "name": "",
    "Site": "",
    "Line": "",
    "authorization_level": ""
  } 

function setA(value) {
    success_state = value;
}

function setB(value) {
    userinfo = value;
}

export {success_state, setA, userinfo, setB}