import {
    DesktopOutlined, DashboardOutlined,
    HomeOutlined, ConsoleSqlOutlined,
    SendOutlined, UserSwitchOutlined, StockOutlined,ThunderboltOutlined
  } from '@ant-design/icons';
  
  
  const menuList = [
    {
      title: 'Home',
      key: '/Home',
      icon: <HomeOutlined />,
      isPublic: true,
    },
  
    {
        title: 'Line 1',
        key: '/Line 1',
        icon: <DesktopOutlined />,
        children: [
          {
            title: 'RealTime',
            key: '/RealTime/1',
            icon: <DashboardOutlined />
          },
          {
            title: 'DataVisualize',
            key: '/DataVisualize/1',
            icon: <StockOutlined />
          },
          {
            title: 'Report C1',
            key: '/CReport/1',
            icon: <ConsoleSqlOutlined />
          },
          {
            title: 'Report G1',
            key: '/GReport/1',
            icon: <ConsoleSqlOutlined />
          },
          {
            title: 'Maguire C1',
            key: '/Maguire/1',
            icon: <ConsoleSqlOutlined />
          },
        ]
      },

      {
        title: 'Line 2',
        key: '/Line 2',
        icon: <DesktopOutlined />,
        children: [
          {
            title: 'RealTime',
            key: '/RealTime/2',
            icon: <DashboardOutlined />
          },
          {
            title: 'DataVisualize',
            key: '/DataVisualize/2',
            icon: <StockOutlined />
          },
          {
            title: 'Report C2',
            key: '/CReport/2',
            icon: <ConsoleSqlOutlined />
          },
          {
            title: 'Report G2',
            key: '/GReport/2',
            icon: <ConsoleSqlOutlined />
          },
          {
            title: 'Maguire C2',
            key: '/Maguire/2',
            icon: <ConsoleSqlOutlined />
          },
        ]
      },

      {
        title: 'Line 3',
        key: '/Line 3',
        icon: <DesktopOutlined />,
        children: [
          {
            title: 'RealTime',
            key: '/RealTime/3',
            icon: <DashboardOutlined />
          },
          {
            title: 'DataVisualize',
            key: '/DataVisualize/3',
            icon: <StockOutlined />
          },
          {
            title: 'Report C3',
            key: '/CReport/3',
            icon: <ConsoleSqlOutlined />
          },
          {
            title: 'Report G3',
            key: '/GReport/3',
            icon: <ConsoleSqlOutlined />
          },
          {
            title: 'Maguire C3',
            key: '/Maguire/3',
            icon: <ConsoleSqlOutlined />
          },
        ]
      },
  
      // {
      //   title: 'Alfa Laval',
      //   key: '/Alfa',
      //   icon: <DesktopOutlined />,
      //   children: [
      //     {
      //       title: 'RealTime',
      //       key: '/AlfaRealTime',
      //       icon: <DashboardOutlined />
      //     },
      //     {
      //       title: 'DataVisualize',
      //       key: '/DataVisualAlfaLava',
      //       icon: <StockOutlined />
      //     },
      //     {
      //       title: 'Report',
      //       key: '/AlfaLavalReport',
      //       icon: <ConsoleSqlOutlined />
      //     },
      //   ]
      // },
  
      // {
      //   title: 'Tirtiaux',
      //   key: '/Tirtiaux',
      //   icon: <DesktopOutlined />,
      //   children: [
      //     {
      //       title: 'RealTime',
      //       key: '/TirtiauxRealTime',
      //       icon: <DashboardOutlined />
      //     },
      //     {
      //       title: 'DataVisualize',
      //       key: '/DataVisualTritiaux',
      //       icon: <StockOutlined />
      //     },
      //     {
      //       title: 'Report',
      //       key: '/TirtiauxReport',
      //       icon: <ConsoleSqlOutlined />
      //     },
      //   ]
      // },

      {
        title: 'EnergyUsage',
        key: '/EnergyUsage',
        icon: <ThunderboltOutlined />,
      },
  
      {
        title: 'Settings',
        key: '/NotificationLine1',
        icon: <SendOutlined />,
      },
    
      // {
      //   title: 'Test',
      //   key: '/Testpage1',
      //   icon: <UserSwitchOutlined />
      // }
  
  ]
  
  export default menuList