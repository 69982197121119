import { Button, Col, Divider, Row, DatePicker, Card, Table, Select, Spin } from 'antd'
import React, { useState, useEffect } from 'react'
import { logdata_request, RealtimeData_Request } from '../../api'
import { CModel, CModelMap, GModel } from '../../DataRequest/DataModel'
import { ConvertMonentToDateTime, DataSortTime, GetDateTime } from '../../DataRequest/LogDataRequestWrap'
import { Line, Column } from '@antv/g2plot';
import moment from 'moment';
import { each, groupBy } from '@antv/util';




let DTinitial = GetDateTime(0, 1)  // get from yesterday 00:00 to today 00:00
let BlankPage = 1, DTc = [], DTg = []
let EnergyDTRange = [], GDTRange = []
let MonthlyEnergyDataSet = []
let TotalEnergyInmonth = []

const annotations = [];


const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];


DTc.push(moment(DTinitial[2]))
DTc.push(moment(DTinitial[3]))
DTg = DTc


let LogValue = {
    'EnergyTable': [],
    "GTable": [],
    "CGraph": [],
    "GGraph": [],
    "CSelected": [],
    "GSelected": [],
}

let LogSelect = {
    LineSelect: 'C1',
    paraname: 'Power Meter'
}

const EnergyOption = ['C1', 'C2', 'C3', 'G1', 'G2', 'G3']

const { RangePicker } = DatePicker;
const { Option } = Select;

let EnergyLine = ['C1', 'C2', 'C3', 'G1', 'G2', 'G3']

LogValue['CSelected'] = [EnergyOption[2]];


let data_filterC = EnergyLine.map(obj => {
    let para

    if (obj[0] === 'C') {
        para = 'Power Meter'
    }
    if (obj[0] === 'G') {
        para = 'Total Energy'
    }

    let cobj = []

    cobj = {
        text: obj,
        value: para,
    }
    return cobj
})

const parseDMYhmC = (s) => {
    var b = s.split(/\D/);
    return new Date(b[2], b[1] - 1, b[2], b[3], b[4]);
}

const columnsC = [
    {
        title: 'Parameter',
        dataIndex: 'Parameter',
        filters: data_filterC,
        filterMode: 'tree',
        filterSearch: true,
        onFilter: (value, record) => record.Parameter.includes(value),
        width: '30%',
    },
    {
        title: 'Value',
        dataIndex: 'Value',
    },
    {
        title: 'Date',
        dataIndex: 'date',
        sorter: (a, b) => parseDMYhmC(a.date) - parseDMYhmC(b.date),
    },
];



let line, stackedColumnPlot

const EnergyUsage = () => {

    const [state, setState] = useState({ count: 0 });
    const [Loading, setLoading] = useState(false);

    useEffect(() => {
        console.log('Reffect');

        BarChartInit(['Power Meter'], DTinitial[0], DTinitial[1], 'Polymerlink', 'C3', '1')

        stackedColumnPlot = new Column('container', {
            data: MonthlyEnergyDataSet,
            isStack: true,
            xField: 'Date',
            yField: 'value',
            seriesField: 'type',
            label: {
                position: 'middle',
                layout: [
                    { type: 'interval-adjust-position' },
                    { type: 'interval-hide-overlap' },
                    { type: 'adjust-color' },
                ],
            },
            annotations,
        });

        try { stackedColumnPlot.render(); }
        catch { }

        return () => {
            stackedColumnPlot.destroy()
        }
    }, [])

    const UpdateTableData = async (Parameter, StartTime, EndTime, Site, Line, Page, BufferName, Model) => {
        console.log(Parameter)
        console.log(Line)
        console.log(StartTime)
        console.log(EndTime)
        const response = await logdata_request(Parameter, StartTime, EndTime, Site, Line, Page)
        let data = DataSortTime(response)
        LogValue[BufferName] = data
        console.log(data);
        setState(({ count }) => ({ count: count + 1 }));
    }

    const BarChartInit = async (Parameter, StartTime, EndTime, Site, Line, Page) => {
        setLoading(true)
        // console.log(Parameter)
        MonthlyEnergyDataSet = []

        for (let lineNo = 1; lineNo <= 3; lineNo++) {
            for (let month = 0; month < 6; month++) {
                let date = new Date();
                let firstDay = new Date(date.getFullYear(), date.getMonth() - month, 1);
                let lastDay = new Date(date.getFullYear(), date.getMonth() + 1 - month, 0);
                let dat = [], MonthlyEnergy = []


                dat.push(firstDay)
                dat.push(lastDay)

                let DateInterval = ConvertMonentToDateTime(dat)

                const response = await logdata_request(Parameter, DateInterval[0], DateInterval[1], Site, "C".concat(lineNo), Page)
                console.log(response);

                if (response.data.length > 0) {
                    let length = response.data.length
                    MonthlyEnergy[month] = (response.data[length - 1].fields.Value - response.data[0].fields.Value).toFixed(2)
                }

                let Energy = {
                    "type": "C".concat(lineNo),
                    "value": parseFloat(MonthlyEnergy[month]),
                    "Date": monthNames[firstDay.getMonth()]
                }

                MonthlyEnergyDataSet.unshift(Energy)
            }
            console.log(MonthlyEnergyDataSet);

            for (let month = 0; month < 6; month++) {
                let date = new Date();
                let firstDay = new Date(date.getFullYear(), date.getMonth() - month, 1);
                let lastDay = new Date(date.getFullYear(), date.getMonth() + 1 - month, 0);
                let dat = [], MonthlyEnergy = []


                dat.push(firstDay)
                dat.push(lastDay)

                let DateInterval = ConvertMonentToDateTime(dat)

                const response = await logdata_request(['Total Energy'], DateInterval[0], DateInterval[1], Site, "G".concat(lineNo), Page)
                console.log(response);

                if (response.data.length > 0) {
                    let length = response.data.length
                    MonthlyEnergy[month] = (response.data[length - 1].fields.Value - response.data[0].fields.Value).toFixed(2)
                }

                let Energy = {
                    "type": "G".concat(lineNo),
                    "value": parseFloat(MonthlyEnergy[month]),
                    "Date": monthNames[firstDay.getMonth()]
                }

                MonthlyEnergyDataSet.unshift(Energy)
            }
            console.log(MonthlyEnergyDataSet);
        }

        // MonthlyEnergyDataSet

        each(groupBy(MonthlyEnergyDataSet, 'Date'), (values, k) => {
            const value = values.reduce((a, b) => a + b.value, 0);
            annotations.push({
                type: 'text',
                position: [k, value],
                content: `${value}`,
                style: { textAlign: 'center', fontSize: 14, fill: 'rgba(0,0,0,0.85)' },
                offsetY: -10,
            });
        });
        console.log(annotations)
        console.log(MonthlyEnergyDataSet);
        stackedColumnPlot.changeData(MonthlyEnergyDataSet)
        setLoading(false)
        // setState(({ count }) => ({ count: count + 1 }));
    }

    const ChangeCSelect = (value) => {

        if (value[0] === 'C') {
            LogSelect.LineSelect = value
            LogSelect.paraname = 'Power Meter'
        }

        if (value[0] === 'G') {
            LogSelect.LineSelect = value
            LogSelect.paraname = 'Total Energy'
        }
    }

    const EnergyLogRequest = async (DT) => {
        DTc = DT
        console.log(DTc);
        EnergyDTRange = ConvertMonentToDateTime(DT)
        console.log(EnergyDTRange);

        let ParaRequest = [LogSelect.paraname]

        console.log(ParaRequest);
        console.log(LogSelect.LineSelect);

        await UpdateTableData(ParaRequest, EnergyDTRange[0], EnergyDTRange[1], 'Polymerlink', LogSelect.LineSelect, BlankPage, 'EnergyTable', CModelMap)
    }

    return (
        <div>
            <div style={{ paddingBottom: '10px' }}>
                <Divider orientation="left">Energy Consumption</Divider>
            </div>


            <Spin tip="Loading..." spinning={Loading} size="large">
                <Card title={'Energy (Kwh)'} bordered={true} style={{ width: '100%', borderRadius: 15, backgroundColor: '#ffffff' }} hoverable={true}>
                    <div id='container'></div>
                </Card>
            </Spin>


            <div style={{ paddingBottom: '10px' }}>
                <Divider orientation="left">Energy Log</Divider>
            </div>

            <div style={{ paddingBottom: '10px' }}>
                <RangePicker onChange={EnergyLogRequest} />
                <Button type='primary' style={{ marginLeft: '10px' }} 
                    onClick={() => { 
                        LogValue['EnergyTable'] = []
                        setState(({ count }) => ({ count: count + 1 }));
                        EnergyLogRequest(DTc) }
                    }>Update
                </Button>
            </div>

            <div style={{ paddingBottom: '20px' }}>
                <Select defaultValue="C1" style={{ width: 70 }} onChange={ChangeCSelect}>
                    {EnergyOption.map(line => <Option value={line}>{line}</Option>)}  
                </Select>
            </div>

            <Card title='Energy Log' bordered={true}
                style={{ width: '100%', borderRadius: 15, marginBottom: '10px' }} hoverable={true}>
                <Table bordered columns={columnsC} dataSource={LogValue['EnergyTable']} size="small" scroll={{ y: 500 }} pagination={false} />
            </Card>
        </div>
    )
}

export default EnergyUsage

