import { Button, Col, Divider, Row, DatePicker, Card, Table, Select } from 'antd'
import React, { useState, useEffect } from 'react'
import { logdata_request, RealtimeData_Request } from '../../api'
import { CModel, CModelMap, GModel } from '../../DataRequest/DataModel'
import { ConvertMonentToDateTime, DataSortTime, GetDateTime } from '../../DataRequest/LogDataRequestWrap'
import { Line } from '@antv/g2plot';
import moment from 'moment';
// import { TextField } from '@material-ui/core';



let DTinitial = GetDateTime(1,1)
let BlankPage = 1, DTc = [] , DTg = []
let CDTRange = [], GDTRange = []

DTc.push(moment(DTinitial[2]))
DTc.push(moment(DTinitial[3]))
DTg = DTc


let LogValue = {
    "CTable": [],
    "GTable": [],
    "CGraph": [],
    "GGraph": [],
    "CSelected": [],
    "GSelected": [],
}
const { RangePicker } = DatePicker;
const { Option } = Select;


let OptionC = Object.keys(CModelMap)
let OptionG = Object.keys(GModel)

const OptionSelectorC = [];
const OptionSelectorG = [];

LogValue['CSelected'] = [OptionC[0], OptionC[1]];
LogValue['GSelected'] = [OptionG[0], OptionG[1]];

OptionC.forEach(element => {
    OptionSelectorC.push(<Option key={element}>{element}</Option>)
});
OptionG.forEach(element => {
    OptionSelectorG.push(<Option key={element}>{element}</Option>)
});

let data_filterC3 = OptionC.map(obj => {
    let cobj = []

    cobj = {
        text: obj,
        value: obj,
    }
    return cobj
})

const parseDMYhmC3 = (s) => {
    var b = s.split(/\D/);
    return new Date(b[2], b[1] - 1, b[2], b[3], b[4]);
}

const columnsC = [
    {
        title: 'Parameter',
        dataIndex: 'Parameter',
        filters: data_filterC3,
        filterMode: 'tree',
        filterSearch: true,
        onFilter: (value, record) => record.Parameter.includes(value),
        width: '30%',
    },
    {
        title: 'Value',
        dataIndex: 'Value',
        sorter: (a, b) => a.Value - b.Value,
    },
    {
        title: 'Date',
        dataIndex: 'date',
        sorter: (a, b) => parseDMYhmC3(a.date) - parseDMYhmC3(b.date),
    },
];



let data_filterG2 = OptionG.map(obj => {
    let cobj = []

    cobj = {
        text: obj,
        value: obj,
    }
    return cobj
})

const parseDMYhmG2 = (s) => {
    var b = s.split(/\D/);
    return new Date(b[2], b[1] - 1, b[2], b[3], b[4]);
}

const columnsG = [
    {
        title: 'Parameter',
        dataIndex: 'Parameter',
        filters: data_filterG2,
        filterMode: 'tree',
        filterSearch: true,
        onFilter: (value, record) => record.Parameter.includes(value),
        width: '30%',
    },
    {
        title: 'Value',
        dataIndex: 'Value',
        sorter: (a, b) => a.Value - b.Value,
    },
    {
        title: 'Date',
        dataIndex: 'date',
        sorter: (a, b) => parseDMYhmG2(a.date) - parseDMYhmG2(b.date),
    },
];

let line, line2

const Line1DataLog = (props) => {

    console.log(props)
    let lineNo = props.match.params.id

    const [state, setState] = useState({ count: 0 });

    useEffect(() => {
        console.log('Reffect');

        line = new Line('CGraph', {
            data: LogValue['CTable'],
            xField: 'date',
            yField: 'Value',
            seriesField: 'Parameter',
        })

        try { 
            line.render() 
        }
        catch { }

        line2 = new Line('GGraph', {
            data: LogValue['GTable'],
            xField: 'date',
            yField: 'Value',
            seriesField: 'Parameter',
        })

        try { line2.render() }
        catch { }

        return () => {
            console.log('Destroy Line');
            line.destroy();
            line2.destroy();

        }
    }, [])

    useEffect(() => {
        console.log('Clear');
        LogValue = {
            "CTable": [],
            "GTable": [],
            "CGraph": [],
            "GGraph": [],
            "CSelected": [],
            "GSelected": [],
        }

        LogValue['CSelected'] = [OptionC[0], OptionC[1]];
        LogValue['GSelected'] = [OptionG[0], OptionG[1]];

        // DTc=[]
        // DTg=[]
        // CDTRange=[]
        // GDTRange=[]

        line.changeData(LogValue["CTable"])
        line2.changeData(LogValue["GTable"])

        setState(({ count }) => ({ count: count + 1 }));
       
        return () => {
            console.log('Unmount Effect Line1DataLog');
        }

    }, [props])

    

    const UpdateTableData = async (Parameter, StartTime, EndTime, Site, Line, Page, BufferName, Model) => {
        console.log(Parameter);
        const response = await logdata_request(Parameter, StartTime, EndTime, Site, Line, Page)
        let data = DataSortTime(response)
        LogValue[BufferName] = data
        console.log(response);
        setState(({ count }) => ({ count: count + 1 }));
    }

    const CLogRequest = async (DT) => {
        // DTc = DT
        // console.log(DTc);
        CDTRange = ConvertMonentToDateTime(DT)
        console.log(CDTRange);
        console.log(LogValue['CSelected']);
        await UpdateTableData(LogValue['CSelected'], CDTRange[0], CDTRange[1], 'Polymerlink', 'C'.concat(lineNo), BlankPage, "CTable", CModelMap)
        console.log(LogValue["CTable"]);
        line.changeData(LogValue["CTable"])
    }

    const GLogRequest = async (DT) => {
        // DTg = DT
        GDTRange = ConvertMonentToDateTime(DT)
        await UpdateTableData(LogValue['GSelected'], GDTRange[0], GDTRange[1], 'Polymerlink', 'G'.concat(lineNo), BlankPage, "GTable", GModel)
        line2.changeData(LogValue["GTable"])
    }

    const DateTimeC = (DT) => {
        DTc = DT
    }

    const DateTimeG = (DT) => {
        DTg = DT
    }

    const ChangeCSelect = (value) => {
        LogValue['CSelected'] = value
    }

    const ChangeGSelect = (value) => {
        LogValue['GSelected'] = value

    }

 

    return (
        <div>
            <div style={{ paddingBottom: '10px' }}>
                <Divider orientation="left">C{lineNo} Log Visualize</Divider>
            </div>
            <div style={{ paddingBottom: '10px' }}>
                <RangePicker showTime onOk={DateTimeC} />
                <Button type='primary' style={{ marginLeft: '10px' }} onClick={() => { CLogRequest(DTc) }}>Update</Button>
            </div>
            <Select
                mode="multiple"
                size="middle"
                placeholder="Please select"
                defaultValue={[OptionC[0], OptionC[1]]}
                style={{ width: '100%', paddingBottom: '20px' }}
                onChange={ChangeCSelect}
            >
                {OptionSelectorC}
            </Select>

            <Card title={'Log Value C' + lineNo} bordered={true}
                style={{ width: '100%', borderRadius: 15, marginBottom: '10px' }} hoverable={true}>
                <Table bordered columns={columnsC} dataSource={LogValue['CTable']} size="small" scroll={{ y: 500 }} pagination={false} />
            </Card>

            <Card title={'C' + lineNo + ' Graph Visualize'} bordered={true} style={{ width: '100%', borderRadius: 15, backgroundColor: '#ffffff' }} hoverable={true}>
                <div id='CGraph'></div>
            </Card>


            <div style={{ paddingBottom: '10px', paddingTop:'20px' }}>
                <Divider orientation="left">G{lineNo} Log Visualize</Divider>
            </div>

            <div style={{ paddingBottom: '10px' }}>
                <RangePicker showTime onOk={DateTimeG} />
                <Button type='primary' style={{ marginLeft: '10px' }} onClick={() => { GLogRequest(DTg) }}>Update</Button>
            </div>

            <Select
                mode="multiple"
                size="middle"
                placeholder="Please select"
                defaultValue={[OptionG[0], OptionG[1]]}
                style={{ width: '100%', paddingBottom: '20px'   }}
                onChange={ChangeGSelect}

            >
                {OptionSelectorG}
            </Select>

            <Card title={'Log Value G' + lineNo} bordered={true}
                style={{ width: '100%', borderRadius: 15, marginBottom: '10px' }} hoverable={true}>
                <Table bordered columns={columnsG} dataSource={LogValue['GTable']} size="small" scroll={{ y: 500 }} pagination={false} />
            </Card>

            <Card title={'G' + lineNo + ' Graph Visualize'} bordered={true} style={{ width: '100%', borderRadius: 15, backgroundColor: '#ffffff' }} hoverable={true}>
                <div id='GGraph'></div>
            </Card>




        </div>
    )
}

export default Line1DataLog
