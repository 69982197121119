export const CModel = {
    'Temperature 1': { PV: 'None', SV: 'None', Name: 'Zone 1 Temp Control' },
    'Temperature 2': { PV: 'None', SV: 'None', Name: 'Zone 2 Temp Control' },
    'Temperature 3': { PV: 'None', SV: 'None', Name: 'Zone 3 Temp Control' },
    'Temperature 4': { PV: 'None', SV: 'None', Name: 'Zone 4 Temp Control' },
    'Temperature 5': { PV: 'None', SV: 'None', Name: 'Zone 5 Temp Control' },
    'Temperature 6': { PV: 'None', SV: 'None', Name: 'Zone 6 Temp Control' },
    'Temperature 7': { PV: 'None', SV: 'None', Name: 'Zone 7 Temp Control' },
    'Temperature 8': { PV: 'None', SV: 'None', Name: 'Zone 8 Temp Control' },
    'Temperature 9': { PV: 'None', SV: 'None', Name: 'Zone 9 Temp Control' },
    'Temperature 12': { PV: 'None', SV: 'None', Name: 'Zone 10 Temp Control' },
    'Temperature 13': { PV: 'None', SV: 'None', Name: 'Screen Change Heating' },
    'Temperature 10': { PV: 'None', SV: 'None', Name: 'Die-Head Temp Ctrl' },
    'Temperature 11': { PV: 'None', SV: 'None', Name: 'Melt Temperature' },
    'RPM 1': { PV: 'None', SV: 'None', Name: 'Main Speed' },
    'RPM 2': { PV: 'None', SV: 'None', Name: 'Main Motor Current' },
    'RPM 3': { PV: 'None', SV: 'None', Name: 'Feeder Speed' },
    'Pressure 1': { PV: 'None', SV: 'None', Name: 'Melt Pressure' },

   
}

export const CModelMap = {
    'Zone 1 Temp Control': { PV: 'None', SV: 'None', Name: 'Zone 1 Temp Control' },
    'Zone 2 Temp Control': { PV: 'None', SV: 'None', Name: 'Zone 2 Temp Control' },
    'Zone 3 Temp Control': { PV: 'None', SV: 'None', Name: 'Zone 3 Temp Control' },
    'Zone 4 Temp Control': { PV: 'None', SV: 'None', Name: 'Zone 4 Temp Control' },
    'Zone 5 Temp Control': { PV: 'None', SV: 'None', Name: 'Zone 5 Temp Control' },
    'Zone 6 Temp Control': { PV: 'None', SV: 'None', Name: 'Zone 6 Temp Control' },
    'Zone 7 Temp Control': { PV: 'None', SV: 'None', Name: 'Zone 7 Temp Control' },
    'Zone 8 Temp Control': { PV: 'None', SV: 'None', Name: 'Zone 8 Temp Control' },
    'Zone 9 Temp Control': { PV: 'None', SV: 'None', Name: 'Zone 9 Temp Control' },
    'Zone 10 Temp Control': { PV: 'None', SV: 'None', Name: 'Zone 10 Temp Control' },
    'Screen Change Heating': { PV: 'None', SV: 'None', Name: 'Screen Change Heating' },
    'Die-Head Temp Ctrl': { PV: 'None', SV: 'None', Name: 'Die-Head Temp Ctrl' },
    'Melt Temperature': { PV: 'None', SV: 'None', Name: 'Melt Temperature' },
    'Main Speed': { PV: 'None', SV: 'None', Name: 'Main Speed' },
    'Main Motor Current': { PV: 'None', SV: 'None', Name: 'Main Motor Current' },
    'Feeder Speed': { PV: 'None', SV: 'None', Name: 'Feeder Speed' },
    'Melt Pressure': { PV: 'None', SV: 'None', Name: 'Melt Pressure' },

   
    'Zone 10 Temp Control': { PV: 'None', SV: 'None', Name: 'Zone 10 Temp Control' },
    

}


export const GModel = {

    'Mill 1 Temperature': { PV: 'None', SV: 'None' },
    ' Mill 1 Current': { PV: 'None', SV: 'None' },
    'Mill 1 Feeder': { PV: 'None', SV: 'None' },
    ' Mill 1 Vacuum': { PV: 'None', SV: 'None' },
    ' Mill 2 Temperature': { PV: 'None', SV: 'None' },
    ' Mill 2 Current': { PV: 'None', SV: 'None' },
    ' Mill 2 Feeder': { PV: 'None', SV: 'None' },
    ' Mill 2 Vacuum': { PV: 'None', SV: 'None' },
    ' Blower Vaccum': { PV: 'None', SV: 'None' },
    ' Ambient Temperature': { PV: 'None', SV: 'None' },
    ' Machine Hours': { PV: 'None', SV: 'None' },
    ' Feeeder Hour Total': { PV: 'None', SV: 'None' },
    ' Blower Speed': { PV: 'None', SV: 'None' },
    'Bag': { PV: 'None', SV: 'None' },
    'Total Energy': { PV: 'None', SV: 'None' },

}



export const C3Model = {
    'Temperature 1': { PV: 'None', SV: 'None', Name: 'Zone 1 Temp Control' },
    'Temperature 2': { PV: 'None', SV: 'None', Name: 'Zone 2 Temp Control' },
    'Temperature 3': { PV: 'None', SV: 'None', Name: 'Zone 3 Temp Control' },
    'Temperature 4': { PV: 'None', SV: 'None', Name: 'Zone 4 Temp Control' },
    'Temperature 5': { PV: 'None', SV: 'None', Name: 'Zone 5 Temp Control' },
    'Temperature 6': { PV: 'None', SV: 'None', Name: 'Zone 6 Temp Control' },
    'Temperature 7': { PV: 'None', SV: 'None', Name: 'Zone 7 Temp Control' },
    'Temperature 8': { PV: 'None', SV: 'None', Name: 'Zone 8 Temp Control' },
    'Temperature 9': { PV: 'None', SV: 'None', Name: 'Zone 9 Temp Control' },
    'Temperature 10': { PV: 'None', SV: 'None', Name: 'Die-Head Temp Ctrl' },
    'Temperature 11': { PV: 'None', SV: 'None', Name: 'Melt Temperature' },
    'RPM 1': { PV: 'None', SV: 'None', Name: 'Main Speed' },
    'RPM 2': { PV: 'None', SV: 'None', Name: 'Main Motor Current' },
    'RPM 3': { PV: 'None', SV: 'None', Name: 'Feeder Speed' },
    'Pressure 1': { PV: 'None', SV: 'None', Name: 'Melt Pressure' },
}

export const C3ModelMap = {
    'Zone 1 Temp Control': { PV: 'None', SV: 'None', Name: 'Zone 1 Temp Control' },
    'Zone 2 Temp Control': { PV: 'None', SV: 'None', Name: 'Zone 2 Temp Control' },
    'Zone 3 Temp Control': { PV: 'None', SV: 'None', Name: 'Zone 3 Temp Control' },
    'Zone 4 Temp Control': { PV: 'None', SV: 'None', Name: 'Zone 4 Temp Control' },
    'Zone 5 Temp Control': { PV: 'None', SV: 'None', Name: 'Zone 5 Temp Control' },
    'Zone 6 Temp Control': { PV: 'None', SV: 'None', Name: 'Zone 6 Temp Control' },
    'Zone 7 Temp Control': { PV: 'None', SV: 'None', Name: 'Zone 7 Temp Control' },
    'Zone 8 Temp Control': { PV: 'None', SV: 'None', Name: 'Zone 8 Temp Control' },
    'Zone 9 Temp Control': { PV: 'None', SV: 'None', Name: 'Zone 9 Temp Control' },
    'Die-Head Temp Ctrl': { PV: 'None', SV: 'None', Name: 'Die-Head Temp Ctrl' },
    'Melt Temperature': { PV: 'None', SV: 'None', Name: 'Melt Temperature' },
    'Main Speed': { PV: 'None', SV: 'None', Name: 'Main Speed' },
    'Main Motor Current': { PV: 'None', SV: 'None', Name: 'Main Motor Current' },
    'Feeder Speed': { PV: 'None', SV: 'None', Name: 'Feeder Speed' },
    'Melt Pressure': { PV: 'None', SV: 'None', Name: 'Melt Pressure' },
}


export const G2Model = {

    'Mill 1 Temperature': { PV: 'None', SV: 'None' },
    ' Mill 1 Current': { PV: 'None', SV: 'None' },
    'Mill 1 Feeder': { PV: 'None', SV: 'None' },
    ' Mill 1 Vacuum': { PV: 'None', SV: 'None' },
    ' Mill 2 Temperature': { PV: 'None', SV: 'None' },
    ' Mill 2 Current': { PV: 'None', SV: 'None' },
    ' Mill 2 Feeder': { PV: 'None', SV: 'None' },
    ' Mill 2 Vacuum': { PV: 'None', SV: 'None' },
    ' Blower Vaccum': { PV: 'None', SV: 'None' },
    ' Ambient Temperature': { PV: 'None', SV: 'None' },
    ' Machine Hours': { PV: 'None', SV: 'None' },
    ' Feeeder Hour Total': { PV: 'None', SV: 'None' },
    ' Blower Speed': { PV: 'None', SV: 'None' },
    'Bag': { PV: 'None', SV: 'None' },
    'Total Energy': { PV: 'None', SV: 'None' },

}

export const JobModel = {
    'Job No': '',
    'Start Time': '',
    'End Time': '',
    'Grade': '',
    'Color': '',
}

// export const McGuire = {
//     'PROPERTY_1': '',
//     'PROPERTY_2': '',
//     'PROPERTY_3': '',
//     'PROPERTY_4': '',
//     'PROPERTY_5': '',
//     'FINAL_DISP_FINAL_1': '',
//     'FINAL_DISP_FINAL_2': '',
//     'FINAL_DISP_FINAL_3': '',
//     'FINAL_DISP_FINAL_4': '',
//     'FINAL_DISP_FINAL_5': '',
//     'FINAL_DISP_TOTAL': '',
//     'FINAL_DISP_INIT_1': '', 
//     'FINAL_DISP_INIT_2': '', 
//     'FINAL_DISP_INIT_3': '', 
//     'FINAL_DISP_INIT_4': '', 
//     'FINAL_DISP_INIT_5': '',
//     'RETRY_1': '', 
//     'RETRY_2': '', 
//     'RETRY_3': '', 
//     'RETRY_4': '', 
//     'RETRY_5': ''
// }


export const McGuire = {
    'FINAL_DISP_FINAL_1': '',
    'PROPERTY_1': '',
    'FINAL_DISP_INIT_1': '', 
    'RETRY_1': '', 
    'FINAL_DISP_FINAL_2': '',
    'PROPERTY_2': '',
    'FINAL_DISP_INIT_2': '', 
    'RETRY_2': '', 
    'FINAL_DISP_FINAL_3': '',
    'PROPERTY_3': '',
    'FINAL_DISP_INIT_3': '', 
    'RETRY_3': '', 
    'FINAL_DISP_FINAL_4': '',
    'PROPERTY_4': '',
    'FINAL_DISP_INIT_4': '', 
    'RETRY_4': '', 
    'FINAL_DISP_FINAL_5': '',
    'PROPERTY_5': '',
    'FINAL_DISP_INIT_5': '',
    'RETRY_5': '',
    'FINAL_DISP_TOTAL': ''  
}

