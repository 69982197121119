import { Form, Input, Button, Checkbox, Card, message } from 'antd';
import './Login.css'
import logo from '../../assets/poly2.png'
import memoryUtils from '../../utils/memoryUtils'
import storageUtils from '../../utils/storageUtils'
import { Redirect} from "react-router-dom"
import React, { Component } from 'react'
import { LoginBackend } from '../../api/index'
import {success_state, setA} from '../constants'

// const result = {
//     status: 0,
//     data: 'tan',
//     msg: 'salah pass lah!'
// }

export class Login extends Component {

    onFinish = async (values) => {

        const { username, password } = values

        const result = await LoginBackend(username, password)

        if (result !== 'false') { //success

            // save user info to local
            // const user = {
            //     name: username,
            //     site: result.Site,
            //     role: username,
            //     autolevel: parseInt(result.authorization_level)
            // }
            // memoryUtils.user = user
            // storageUtils.saveUser(user)
            setA(true)
            
            message.success('Login Success')
            this.props.history.replace('/')
        }

        else { //wrong pass or name
            setA(false)

        }

    };

    onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    render() {
        // const user = memoryUtils.user
        if (success_state) {
            // console.log('smtg')
            // console.log(Object.keys(user).length)

            return <Redirect push to="/" />
            // return <Redirect to='/' />
        }
        else {
            console.log('ntg')
            // console.log(Object.keys(user).length)
        }

        return (
            <div className='login'>

                <header className="login-header">
                    <img src={logo} alt="logo" />
                    <h1>PLM-IOT</h1>
                </header>

                <Card className="login-content">

                    <section >
                        <h2>Login</h2>

                        <Form
                            name="basic"
                            labelCol={{
                                span: 8,
                            }}
                            wrapperCol={{
                                span: 16,
                            }}
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={this.onFinish}
                            onFinishFailed={this.onFinishFailed}
                            autoComplete="off"
                            className="login-form"
                        >
                            <Form.Item
                                label="Username"
                                name="username"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your username!',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your password!',
                                    },
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>

                            <Form.Item
                                wrapperCol={{
                                    offset: 8,
                                    span: 16,
                                }}
                            >
                                <Button type="primary" htmlType="submit">
                                    Login
                                </Button>
                            </Form.Item>
                        </Form>
                    </section>
                </Card>
            </div>
        )
    }
}

export default Login
